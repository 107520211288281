import { axios } from '../../exios';
import { AxiosResponse } from 'axios';
import { Base64ToBlob } from '../../helpers/Base64ToBlob';
import { CHAT_URL } from '../../../packages/keycloak-client/constants';
import {
  EntityType,
  GetMessagesRequestType,
  IChat,
  ICountChats,
  SearchAttachmentsPayloadType,
  UploadAttachmentToMinioType,
} from './ChatsTypes';

export const getUnreadMessagesCount = async () => {
  return await axios.get<ICountChats>(`${CHAT_URL}/admin/message/get-count`);
};

export const getMessages = async (entity: EntityType, id: string) => {
  return axios.post<AxiosResponse<GetMessagesRequestType>>(
    `${CHAT_URL}/admin/message/search`,
    {
      entity_type: entity,
      entity_id: id,
      sort: [
        {
          field: 'created_at',
          direction: 'asc',
        },
      ],
      limit: 1000,
    }
  );
};

export const getChatInfo = async (
  entity: EntityType,
  id: string,
  uid: string
) => {
  return axios.post<AxiosResponse<IChat>>(`${CHAT_URL}/admin/chat/info`, {
    entity_type: entity,
    entity_id: id,
    client_uid: uid,
  });
};

export const sendMessage = async (
  entity: EntityType,
  id: string,
  messageText: string,
  hasAttachment: boolean
) => {
  if (messageText?.trim().length) {
    return axios.post<AxiosResponse<any>>(`${CHAT_URL}/admin/message`, {
      entity_type: entity,
      entity_id: id,
      text: messageText,
      has_attachment: hasAttachment,
    });
  } else {
    return axios.post<AxiosResponse<any>>(`${CHAT_URL}/admin/message`, {
      entity_type: entity,
      entity_id: id,
      has_attachment: hasAttachment,
    });
  }
};

export const postAttachmentStep1 = async (
  payload: SearchAttachmentsPayloadType
) => {
  return axios.post<AxiosResponse<any>>(
    `${CHAT_URL}/admin/attachment`,
    payload
  );
};

export const postAttachmentStep2 = async (
  payload: UploadAttachmentToMinioType
) => {
  const [header, base64] = payload.files[0].preview.split(',');
  const contentType = header.split(':')[1].split(';')[0];
  const blob = Base64ToBlob(base64, contentType);
  return fetch(payload.upload_link, {
    method: 'put',
    body: blob,
    headers: {
      'Content-Type': payload?.type ? payload?.type : '',
    },
  });
};

export const postAttachmentStep3 = async (
  attachment_id: string | number | null
) => {
  return axios.put<AxiosResponse<any>>(
    `${CHAT_URL}/admin/attachment/${attachment_id}`,
    {
      is_uploaded: true,
    }
  );
};
