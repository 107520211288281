import { default as baseAxios } from 'axios';
import { API_URL } from '../packages/keycloak-client/constants';
import { getCookieByName } from './helpers/controlCookies';
import { useAuthState } from './state/useAuthState';

export const axios = baseAxios.create({ baseURL: API_URL });

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      if (
        error.response.data.error === 'Unauthenticated' ||
        error.response.data.message === 'Unauthenticated'
      ) {
        const originalRequest = error.config;
        return await useAuthState.getState().refreshToken(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use((config) => {
  if (getCookieByName('token')) {
    config.headers!.Authorization = `Bearer ${getCookieByName(
      'token'
    )}`.replace('Bearer Bearer', 'Bearer');
  }

  return config;
});
