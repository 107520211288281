import { getCookieByName } from '../../helpers/controlCookies';
import { default as baseAxios } from 'axios';
import { ACCOUNT_URL_API } from '../../../packages/keycloak-client/constants';

export const getAccessTokenToConnectWS = async () => {
  if (!getCookieByName('refresh_token')) return;
  const res = await baseAxios.post(
    `${ACCOUNT_URL_API}/server/edenex-account/api/refresh-token`,
    {
      refresh_token: getCookieByName('refresh_token')!.replace('Bearer ', ''),
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: '',
      },
    }
  );

  return res.data.data.access_token;
};
