import { devtools } from 'zustand/middleware';
import { create } from 'zustand';
import { produce } from 'immer';
import { Centrifuge } from 'centrifuge';
import {
  centrifugeInstanceConnect,
  centrifugeInstanceCreate,
  centrifugeInstanceDisconnect,
} from '../../api/CentrifugeAPI/CentrifugeAPI';

type ICentrifugeState = {
  centrifugeInstance: Centrifuge | null;
  centrifugeInstanceState: 'connected' | 'disconnected' | 'connecting';
  createCentrifugeInstance: () => void;
  connectToCentrifugeInstance: () => void;
  disconnectCentrifugeInstance: () => void;
};

export const useCentrifugeState = create<ICentrifugeState>()(
  devtools(
    (set, get) => ({
      centrifugeInstance: null,
      centrifugeInstanceState: 'disconnected',
      createCentrifugeInstance: () => {
        let centrifugeInstance = centrifugeInstanceCreate();
        set(
          produce((draft) => {
            draft.centrifugeInstance = centrifugeInstance;
          })
        );
      },
      connectToCentrifugeInstance: () => {
        const status = centrifugeInstanceConnect();
        set(
          produce((draft) => {
            draft.centrifugeInstanceState = status;
          })
        );
      },
      disconnectCentrifugeInstance: () => {
        const status = centrifugeInstanceDisconnect();
        set(
          produce((draft) => {
            draft.centrifugeInstanceState = status;
          })
        );
      },
    }),
    {
      name: 'useCentrifugeState',
      anonymousActionType: 'useCentrifugeState action',
    }
  )
);
