import React, { useState } from 'react';
import { NavLink, useNavigate } from '@pankod/refine-react-router-v6';
import Icon from '@ant-design/icons';
import { Typography } from '@pankod/refine-antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { ACCOUNT_URL } from '../../../../packages/keycloak-client/constants';
import styled from 'styled-components';

interface IDropdownRow {
  key: string;
  isOutside: boolean;
  link: any;
  iconComp: () => JSX.Element;
  text: string;
  target?: string;
  onClickAction?: () => void;
  aboutCompany?: boolean;
  customWithRedirect?: boolean;
  description?: string;
}

const DropdownRowWithHoverIcon = ({
  isOutside,
  link,
  iconComp,
  text,
  key,
  target,
  onClickAction,
  aboutCompany,
  description,
}: IDropdownRow): ItemType => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  function addLinks(link: string, key: string, target: string | undefined) {
    if (isOutside) {
      if (key === 'profile') {
        return (
          <a
            href={`${ACCOUNT_URL}/server/account/settings`}
            target={'_blank'}
            rel="noreferrer"
          >
            <LinkInnerStyled>
              <Icon component={iconComp} />
              <Typography.Text>{text}</Typography.Text>
            </LinkInnerStyled>
          </a>
        );
      } else {
        return link ? (
          <a href={link} target={target ? target : ''}>
            <LinkInnerStyled>
              <Icon component={iconComp} />
              <Typography.Text>{text}</Typography.Text>
            </LinkInnerStyled>
          </a>
        ) : (
          <LinkInnerStyled>
            <Icon component={iconComp} />
            <Typography.Text>{text}</Typography.Text>
          </LinkInnerStyled>
        );
      }
    } else {
      if (key === 'aboutCompany') {
        return (
          <CompanyWrapper>
            <TextsWrapper>
              <CompanyStyled>{text}</CompanyStyled>
              <CurrencyStyled>{description}</CurrencyStyled>
            </TextsWrapper>
            <EditButtonWrapper>
              <Icon component={iconComp} />
            </EditButtonWrapper>
          </CompanyWrapper>
        );
      }
      if (key === 'goToMap') {
        return (
          <LinkToMapBlockStyled>
            <LinkToMapLabelStyled>{text}</LinkToMapLabelStyled>
            <div>
              <Icon component={iconComp} />
            </div>
          </LinkToMapBlockStyled>
        );
      } else {
        return link ? (
          <NavLink to={link}>
            <LinkInnerStyled>
              <Icon component={iconComp} />
              <Typography.Text>{text}</Typography.Text>
            </LinkInnerStyled>
          </NavLink>
        ) : (
          <LinkInnerStyled>
            <Icon component={iconComp} />
            <Typography.Text>{text}</Typography.Text>
          </LinkInnerStyled>
        );
      }
    }
  }

  return {
    key,
    onClick: () => {
      if (onClickAction) {
        onClickAction();
      }
      if (link) {
        navigate(link);
      }
    },
    label: (
      <div
        className={'flex-row gap-8 justify-content-space-between'}
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'linear-gradient(#18181F, #2D2D3A)',
        }}
      >
        {addLinks(link, key, target)}
      </div>
    ),
    onMouseLeave: () => setIsHovered(false),
    onMouseEnter: () => setIsHovered(true),
  };
};

const LinkInnerStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const CompanyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LinkToMapBlockStyled = styled.div`
  border-top: 1px solid rgba(138, 136, 136, 0.22);
`;

const LinkToMapLabelStyled = styled.div`
  font-weight: 500;
  font-size: 14px;
  padding: 10px 0;
`;

const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const CompanyStyled = styled.p`
  color: black;
  font-weight: 500;
  font-size: 1.1em;
  margin: 0;
`;
const CurrencyStyled = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 0.9em;
`;
const EditButtonWrapper = styled.div`
  display: flex;
  align-items: start;
  padding-top: 4px;
`;
export default DropdownRowWithHoverIcon;
