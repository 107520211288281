import s from './index.module.css';
import PdfFileBlock from '../filePreview/components/pdfFileBlock';
import { TimeMark } from '../time-mark';
import { Download } from '../../assets/DownloadIcon';
import { LinkStyled, ModalStyled } from './assets/styles';
import { useTranslation } from 'react-i18next';
import { IAttachment } from '../../../../shared/api/ChatsAPI/ChatsTypes';

interface IModalImage {
  attachment: IAttachment;
  fileDownloadLink: string | undefined;
  onClose: () => void;
  open: boolean;
  date: Date;
  senderNickname: string | undefined;
}

const MessageModalFile = ({
  date,
  fileDownloadLink,
  open,
  onClose,
  attachment,
  senderNickname,
}: IModalImage) => {
  const { t } = useTranslation();

  return (
    <ModalStyled open={open} onCancel={onClose}>
      <div className={s.imgWrapper}>
        {attachment.file_extension === 'pdf' ? (
          <PdfFileBlock
            name={attachment.file_name}
            size={attachment.id}
            handleDeleteFile={() => {}}
            isShowDelete={false}
          />
        ) : (
          <img src={fileDownloadLink} alt="attachment" />
        )}
      </div>
      <div className={s.img__date}>
        <div>{senderNickname}</div>
        <TimeMark date={date} />
      </div>
      <LinkStyled
        target={'_blank'}
        href={fileDownloadLink}
        className={s.img__download}
        download={attachment.file_name}
      >
        <Download />
        <div>{t('shared:тексты.Скачать')}</div>
      </LinkStyled>
    </ModalStyled>
  );
};

export { MessageModalFile };
