import styled from 'styled-components';
import { Layout as AntdLayout } from 'antd';

interface StyledDivProps {
  noPadding: boolean;
  isSmall: boolean;
  isScreen1300: boolean;
}

export const InfoWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  background: #fff;
  overflow: hidden;
`;

export const InfoMessage = styled.p`
  text-align: center;
`;

export const StyledAntdLayout = styled(AntdLayout)`
  min-height: 100vh;
  background: #f5f5f5;
  position: relative;
`;

export const WhiteLayout = styled(AntdLayout)`
  background: #fcfcfd;
`;

export const WrapperTitleNotification = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000E0;
`;

export const StyledDiv = styled.div<StyledDivProps>`
  min-height: 360px;
  height: 100%;
  padding-bottom: ${({ noPadding }) => (noPadding ? '0px' : '32px')};
  margin-left: ${({ isScreen1300 }) => (isScreen1300 ? '0px' : '80px')};
  padding: ${({ noPadding, isSmall, isScreen1300 }) => {
    if (noPadding) return 0;
    if (isSmall) return isScreen1300 ? '32px' : '16px';
    return '12px';
  }};
`;
