import { devtools } from 'zustand/middleware';
import { create } from 'zustand';
import { produce } from 'immer';
import {
  getChatInfo,
  getMessages,
  getUnreadMessagesCount,
  postAttachmentStep1,
  postAttachmentStep2,
  postAttachmentStep3,
  sendMessage,
} from '../../api/ChatsAPI/ChatsAPI';
import {
  IChat,
  IMessage,
  SearchAttachmentsPayloadType,
  UploadAttachmentToMinioType,
} from '../../api/ChatsAPI/ChatsTypes';

type IChatsState = {
  openedEntity: {
    entityType: 'offer' | 'offer_deal' | null;
    id: string | null;
  };
  setOpenedEntity: (
    entityType: 'offer' | 'offer_deal' | null,
    id: string | null
  ) => void;
  unreadMessagesCounts: {
    clientCount: number;
    corporateCount: number;
  };
  setUnreadMessagesCount: () => void;
  isLoadingMessages: boolean;
  currentMessages: IMessage[];
  getMessages: (dealID: string) => void;
  sendMessage: (dealID: string, message: string, hasAttachment: boolean) => any;
  addNewMessageFromWS: (message: IMessage) => void;
  currentChatInfo: IChat;
  getChatInfo: (dealID: string, uid: string) => void;
  postAttachmentStep1: (payload: SearchAttachmentsPayloadType) => any;
  postAttachmentStep2: (payload: UploadAttachmentToMinioType) => any;
  postAttachmentStep3: (attachment_id: string | number | null) => any;
};

export const useChatsState = create<IChatsState>()(
  devtools(
    (set, get) => ({
      openedEntity: {
        entityType: null,
        id: null,
      },
      setOpenedEntity: (
        entityType: 'offer' | 'offer_deal' | null,
        id: string | null
      ) => {
        set(
          produce((draft) => {
            draft.openedEntity = {
              entityType: entityType,
              id: id,
            };
          })
        );
      },
      unreadMessagesCounts: {
        clientCount: 0,
        corporateCount: 0,
      },
      currentMessages: [] as IMessage[],
      currentChatInfo: {} as IChat,
      isLoadingMessages: true,
      setUnreadMessagesCount: async () => {
        let response = await getUnreadMessagesCount();
        set(
          produce((draft) => {
            draft.unreadMessagesCounts.corporateCount =
              response?.data?.exchange_point?.exchange_points.reduce(
                (prev, next) => prev + next.unread_message_count,
                0
              ) || 0;
            draft.unreadMessagesCounts.clientCount = response?.data?.user
              .unread_message_count
              ? response?.data?.user.unread_message_count
              : 0;
          })
        );
      },
      getMessages: async (dealID: string) => {
        try {
          set(
            produce((draft) => {
              draft.isLoadingMessages = true;
            })
          );
          let response = await getMessages('offer_deal', dealID);
          // убрать filter когда появятся системные сообщения
          set(
            produce((draft) => {
              draft.currentMessages = response?.data?.data.filter(
                (message: IMessage) => !message.is_system
              );
            })
          );
        } catch (error) {
          console.log(error);
        } finally {
          set(
            produce((draft) => {
              draft.isLoadingMessages = false;
            })
          );
        }
      },
      sendMessage: async (
        dealID: string,
        message: string,
        hasAttachment: boolean
      ) => {
        return await sendMessage('offer_deal', dealID, message, hasAttachment);
      },
      addNewMessageFromWS: (message: IMessage) => {
        if (
          get().currentMessages?.length &&
          get().currentMessages[
            get().currentMessages?.length - 1
          ]?.id?.toString() === message.id.toString()
        ) {
          return;
        } else {
          set(
            produce((draft) => {
              draft.currentMessages.push(message);
            })
          );
        }
      },
      getChatInfo: async (dealID: string, uid: string) => {
        try {
          let response = await getChatInfo('offer_deal', dealID, uid);
          set(
            produce((draft) => {
              draft.currentChatInfo = response?.data?.data;
            })
          );
        } catch (error) {
          console.log(error);
        }
      },
      postAttachmentStep1: async (payload: SearchAttachmentsPayloadType) => {
        // step 1 - create attachment
        try {
          const res = await postAttachmentStep1(payload);
          return Promise.resolve({
            lastUploadLink: res?.data?.data?.upload_link,
            lastCreatedAttachmentId: res?.data?.data?.id,
          });
        } catch (e) {
          console.log(e);
          return Promise.reject();
        }
      },
      postAttachmentStep2: async (payload: UploadAttachmentToMinioType) => {
        // step 2 - upload attachment to minio
        try {
          const res = await postAttachmentStep2(payload);
          if (res) {
            return Promise.resolve();
          }
        } catch (e) {
          console.log(e);
          return Promise.reject();
        }
      },
      postAttachmentStep3: async (attachment_id: string | number | null) => {
        // step 3 - change is upload in attachment
        try {
          await postAttachmentStep3(attachment_id);
          return Promise.resolve();
        } catch (e) {
          console.log(e);
          return Promise.reject();
        }
      },
    }),
    {
      name: 'useCentrifugeState',
      anonymousActionType: 'useCentrifugeState action',
    }
  )
);
