import { UserAccountsRole } from 'interfaces';

export const ROLES_BLOCKED_ROUTES: Record<UserAccountsRole, string[]> = {
  admin: [],
  manager: [
    '/offers',
    '/transactions',
    '/daily-reports',
    '/global-payments',
    '/payment-methods',
    '/roles',
  ],
  cashier: [
    '/offers',
    '/transactions',
    '/chains',
    '/daily-reports',
    '/roles',
    '/mmf',
    '/global-payments',
    '/payment-methods',
  ],
};
