import { useUserRole } from '../shared/hooks/useUserRole';
import { UserAccountsRole } from 'interfaces';
import { useNavigation } from '@pankod/refine-core';
import { ROLES_BLOCKED_ROUTES } from 'shared/constants/rolesBlockedRoutes';

export const CheckAccessToPage = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { replace } = useNavigation();
  const userRole = useUserRole();
  const pathname = window.location.pathname;
  const userBlockedRoutes =
    ROLES_BLOCKED_ROUTES[userRole as UserAccountsRole] || [];
  const isBlocked = userBlockedRoutes.some((route) =>
    pathname.startsWith(route)
  );

  if (isBlocked) {
    replace('/accounts');
    return null;
  }

  return <>{children}</>;
};
