import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IMessage } from '../../../../shared/api/ChatsAPI/ChatsTypes';
import {
  AttachmentWrapperStyled,
  ImgStyled,
  MessageAvatarContainer,
  MessageContainerStyled,
  MessageImgStyled,
  MessageParticipantNameStyled,
  MessagePdfTextStyled,
  MessageStyled,
  MessageTextStyled,
  MessageTimeStyled,
  RefereeAvatarStyled,
  UserAvatarStyled,
} from './Chat.styles';
import dayjs from 'dayjs';
import { UserOutlined } from '@ant-design/icons';
import { RefereeOutlined } from '../../../../shared/components/icons';
import { PdfFile } from '../../assets/PdfFileIcon';
import { MessageModalFile } from '../../components/message-modal-file';

type MessageItemProps = {
  message: IMessage;
};

export const MessageItem = ({ message }: MessageItemProps) => {
  const { t } = useTranslation();

  const [imgModalOpen, setImgModalOpen] = useState(false);

  const messageDate = new Date(message?.created_at);
  const userDate = new Date();

  const serverDay = messageDate.getDate();
  const userDay = userDate.getDate();

  const renderAttachment = () => {
    if (!message?.attachment?.is_uploaded) return <></>;

    const attachmentText = () => {
      if (message?.attachment?.file_name?.length) {
        return message?.attachment?.file_name?.length > 30
          ? message?.attachment?.file_name.substring(0, 30) + '...'
          : message?.attachment?.file_name;
      }
    };

    if (message?.attachment?.file_extension === 'pdf') {
      return (
        <MessagePdfTextStyled>
          <div>
            <PdfFile />
          </div>
          <div>{attachmentText()}</div>
        </MessagePdfTextStyled>
      );
    } else if (
      ['jpg', 'png', 'jpeg'].includes(message?.attachment?.file_extension)
    ) {
      return (
        <ImgStyled src={message?.attachment?.download_link} alt="Attachment" />
      );
    } else {
      return undefined;
    }
  };

  const handleOpenModal = () => {
    setImgModalOpen(true);
  };

  return (
    <MessageContainerStyled $isMine={message.is_mine}>
      <MessageAvatarContainer>
        {message.participant.type === 'user' && !message.is_mine && (
          <UserAvatarStyled>
            <UserOutlined />
          </UserAvatarStyled>
        )}
        {message.participant.type === 'referee' && !message.is_mine && (
          <RefereeAvatarStyled>
            <RefereeOutlined />
          </RefereeAvatarStyled>
        )}
      </MessageAvatarContainer>
      <MessageStyled
        $isMine={message.is_mine}
        $hasImage={
          (message?.attachment &&
            message?.attachment?.file_extension !== 'pdf') ??
          false
        }
      >
        {!message?.is_mine && (
          <MessageParticipantNameStyled>
            {message.participant.type === 'user' && (
              <MessageParticipantNameStyled $isClient={true}>
                {t('shared:тексты.Клиент')} · {message.participant.caption}
              </MessageParticipantNameStyled>
            )}
            {message.participant.type === 'referee' && (
              <MessageParticipantNameStyled $isReferee={true}>
                {t('shared:тексты.Рефери')}
              </MessageParticipantNameStyled>
            )}
          </MessageParticipantNameStyled>
        )}
        {!!message?.attachment && (
          <AttachmentWrapperStyled $right={message.is_mine}>
            <MessageImgStyled onClick={handleOpenModal}>
              {renderAttachment()}
            </MessageImgStyled>
          </AttachmentWrapperStyled>
        )}
        {message.text && <MessageTextStyled>{message.text}</MessageTextStyled>}
        <MessageTimeStyled>
          {serverDay !== userDay &&
            dayjs(message.created_at).format('DD.MM.YY · HH:mm')}
          {serverDay === userDay && dayjs(message.created_at).format('HH:mm')}
        </MessageTimeStyled>
      </MessageStyled>
      {message?.attachment && (
        <MessageModalFile
          senderNickname={message?.participant?.caption}
          fileDownloadLink={message.attachment?.download_link}
          attachment={message.attachment}
          open={imgModalOpen}
          onClose={() => {
            setImgModalOpen(false);
          }}
          date={new Date(message.created_at)}
        />
      )}
    </MessageContainerStyled>
  );
};
