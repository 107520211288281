import React, { useEffect, useState } from 'react';
import '@pankod/refine-antd/dist/reset.css';
import { I18nProvider, IRouterProvider, Refine } from '@pankod/refine-core';
import routerProvider, {
  Navigate,
  RefineRouteProps,
} from '@pankod/refine-react-router-v6';
import { Login } from '../pages/login';
import { dataProvider as orionDataProvider } from '@egal/refine-laravel-orion';
import {
  ACCOUNT_DATA_PROVIDER,
  API_URL,
  CONFIG_DATA_PROVIDER,
  EXCHANGE_DATA_PROVIDER,
  PHYSICAL_EXCHANGER_DATA_PROVIDER,
} from '../packages/keycloak-client/constants';
import { useTranslation } from 'react-i18next';
import { Title } from '../shared/components/layout/title';
import { Header } from '../shared/components/layout/header';
import { Sidebar } from '../shared/components/layout/sidebar';
import { Layout } from '../shared/components/layout';
import { axios } from '../shared/exios';
import fb from '../packages/fb/fb';
import { useUserState } from 'shared/state/useUserState';
import AboutAccount from 'pages/aboutAccount';
import CourseHistory from '../pages/courseHistory';
import { ErrorComponent } from '@pankod/refine-antd';
import { SessionMiddleware } from './SessionMiddleware';
import CreateOffer from '../pages/CreateOffer/CreateOffer';
import OffersPage from '../pages/offers';
import OfferPage from '../pages/offer';
import OfferRequestPage from '../pages/offer-request';
import OfferRequestConfirmPage from '../pages/offer-request-confirm';
import { useCookiesCustom } from '../shared/hooks/useCookiesCustom';
import { PaymentMethodPage } from 'pages/PaymentMethod';

const CreateExchangePoint = React.lazy(
  () => import('../pages/createExchangePoint/ui/CreateExchangePoint')
);

const Home = React.lazy(() => import('../pages/home/home'));
const Partners = React.lazy(() => import('../pages/Partners/Partners'));

const QuestionsAnswers = React.lazy(
  () => import('../pages/questionsAndAnswers/QuestionsAnswers')
);

const QuestionsAnswersArticle = React.lazy(
  () => import('../pages/questionsAnswersArticle/QuestionsAnswersArticle')
);

const AccountsList = React.lazy(
  () => import('../pages/accountsList/accountsList')
);
const LoginError = React.lazy(() => import('pages/login-error'));

const Notifications = React.lazy(
  () => import('../pages/notifications/Notifications')
);

const NotificationsSettings = React.lazy(
  () => import('../pages/notifications-settings/NotificationsSettings')
);

const CurrencyPairs = React.lazy(
  () => import('../pages/currencyPairs/CurrencyPairs')
);

const ExchangePoints = React.lazy(() => import('../pages/ExchangePoints'));

const ExchangePoint = React.lazy(() => import('../pages/ExchangePoint'));

const PublishExchangePoint = React.lazy(
  () => import('../pages/publish-exchange-point')
);

const EditExchangePoint = React.lazy(
  () => import('../pages/edit-exchange-point')
);
const EditExchangePointRates = React.lazy(
  () => import('../pages/editExchangePointRates')
);

const DailyReports = React.lazy(() => import('../pages/DailyReports'));

const Currencies = React.lazy(() => import('../pages/Currencies'));

const DailyReport = React.lazy(
  () => import('../pages/DailyReports/dailyReport/DailyReport')
);

const Chains = React.lazy(() => import('../pages/chains/Chains'));

const MoreOneChain = React.lazy(
  () => import('../pages/chains/moreOneChain/MoreOneChain')
);

const Staff = React.lazy(() => import('../pages/Staff'));
const Payment = React.lazy(() => import('../pages/Payment'));

const Roles = React.lazy(() => import('../pages/Roles'));

const CreateCompany = React.lazy(() => import('../pages/create-company'));

const CreateCompanyPortraitMessage = React.lazy(
  () => import('../pages/create-company-portrait-message')
);

const CreateCompanyError = React.lazy(
  () => import('../pages/create-company-error')
);

const MoreOneOperation = React.lazy(
  () => import('../pages/chains/moreOneOperation/MoreOneOperation')
);

const RedirectPage = React.lazy(
  () => import('../pages/redirect-page/RedirectPage')
);

const ConfirmInvite = React.lazy(
  () => import('../pages/confirm-invite/ConfirmInvite')
);

const ClientsPage = React.lazy(() => import('../pages/clients/ClientsPage'));

const Analytics = React.lazy(() => import('../pages/analytics/Analytics'));

const MmfPage = React.lazy(() => import('../pages/mmf/MmfPage'));

const GlobalPaymentsPage = React.lazy(
  () => import('../pages/global-payments/ui/GlobalPaymentsPage')
);

const CreateChainPage = React.lazy(
  () => import('../pages/chains/createChain/CreateChainPage')
);

const AboutStaff = React.lazy(() => import('../pages/aboutStaff'));

const AboutClient = React.lazy(() => import('../pages/aboutClient/index'));

const AccessDeniedError = React.lazy(
  () => import('../pages/access-denied-error')
);

const InviteError = React.lazy(() =>
  import('../pages/invite-error/InviteError').then((module) => ({
    default: module.InviteError,
  }))
);

const Company = React.lazy(() => import('../pages/company'));

function App() {
  const { t, i18n } = useTranslation(['shared']);
  const { cookie } = useCookiesCustom();

  useEffect(() => {
    const checkReflection = () => {
      const ht = document.querySelector('html');
      if (ht && i18n.language === 'ar') {
        ht.className = 'htmlReversed';
      } else if (ht) {
        ht.className = '';
      }
    };
    checkReflection();
  }, [i18n.language]);

  const setUserRole = useUserState((state) => state.setUserRole);
  const setUserEmail = useUserState((state) => state.setUserEmail);
  const isBannedUser = useUserState((state) => state.isBannedUser);
  const userApi = useUserState((state) => state.userApi);

  const i18nProvider: I18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const [dataProvider] = useState({
    default: orionDataProvider(API_URL, axios),
    [EXCHANGE_DATA_PROVIDER]: orionDataProvider(
      `${API_URL}/exchange/api`,
      axios
    ),
    [ACCOUNT_DATA_PROVIDER]: orionDataProvider(`${API_URL}`, axios),
    [CONFIG_DATA_PROVIDER]: orionDataProvider(`${API_URL}/config/api`, axios),
    [PHYSICAL_EXCHANGER_DATA_PROVIDER]: orionDataProvider(`${API_URL}`, axios),
  });

  const resources = isBannedUser
    ? [
        {
          name: 'redirect-page',
          list: RedirectPage,
          show: RedirectPage,
        },
        {
          name: 'access-denied',
          list: AccessDeniedError,
          show: AccessDeniedError,
          options: {
            dataProviderName: '/',
          },
        },
      ]
    : !cookie['token']
    ? [
        {
          name: 'redirect-page',
          list: RedirectPage,
        },
        {
          name: 'home',
          list: Home,
          options: {
            dataProviderName: '/',
          },
        },
      ]
    : !localStorage.getItem('companyId')
    ? [
        {
          name: 'redirect-page',
          list: RedirectPage,
          show: RedirectPage,
        },
        {
          name: 'create-company',
          list: CreateCompany,
        },
      ]
    : [
        {
          name: 'accounts',
          list: AccountsList,
          show: AccountsList,
          options: {
            dataProviderName: '/',
          },
        },
        {
          name: 'redirect-page',
          list: RedirectPage,
          show: RedirectPage,
        },
      ];

  const routesWithoutCompany: RefineRouteProps[] = [
    {
      path: '*',
      element: (
        <SessionMiddleware isAuth={!!cookie['token']}>
          <Navigate to={'/redirect-page'} replace={true} />
        </SessionMiddleware>
      ),
    },
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/create-company',
      element: <CreateCompany />,
    },
    {
      path: '/redirect-page',
      element: <RedirectPage status={isBannedUser} />,
    },
    {
      path: '/confirm-invite',
      element: <ConfirmInvite />,
    },
    {
      path: 'invite-error',
      element: (
        <SessionMiddleware isAuth={!!cookie['token']}>
          <InviteError />
        </SessionMiddleware>
      ),
    },
    {
      path: '/login-error',
      element: <LoginError />,
    },
  ];

  const routesWithCompany: RefineRouteProps[] = [
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/offers/create-offer',
      element: (
        <Layout>
          <CreateOffer />
        </Layout>
      ),
    },
    {
      path: '/offers/:id',
      element: (
        <Layout>
          <OfferPage />
        </Layout>
      ),
    },
    {
      path: '/offers',
      element: (
        <Layout>
          <OffersPage type={'offers'} />
        </Layout>
      ),
    },
    {
      path: '/transactions',
      element: (
        <Layout>
          <OffersPage type={'exchange'} />
        </Layout>
      ),
    },
    {
      path: '/offers/request/:id',
      element: (
        <Layout>
          <OfferRequestPage />
        </Layout>
      ),
    },
    // {
    //     path: '/notifications',
    //     element: (
    //         <Layout>
    //             <Notifications/>
    //         </Layout>
    //     ),
    // },
    // {
    //     path: '/notifications-settings',
    //     element: (
    //         <Layout>
    //             <NotificationsSettings/>
    //         </Layout>
    //     ),
    // },
    {
      path: '/create-company-portrait-message',
      element: <CreateCompanyPortraitMessage />,
    },
    {
      path: '/redirect-page',
      element: <RedirectPage status={isBannedUser} />,
    },
    {
      path: '/confirm-invite',
      element: <ConfirmInvite />,
    },
    {
      path: '/accounts/:id',
      element: (
        <Layout>
          <AboutAccount />
        </Layout>
      ),
    },
    {
      path: '/accounts',
      element: (
        <Layout>
          <AccountsList />
        </Layout>
      ),
    },
    {
      path: '/clients',
      element: (
        <Layout>
          <ClientsPage />
        </Layout>
      ),
    },
    {
      path: '/clients/:id',
      element: (
        <Layout>
          <AboutClient />
        </Layout>
      ),
    },
    {
      path: '/analytics',
      element: (
        <Layout>
          <Analytics />
        </Layout>
      ),
    },
    {
      path: '/mmf',
      element: (
        <Layout>
          <MmfPage />
        </Layout>
      ),
    },
    {
      path: '/global-payments',
      element: (
        <Layout>
          <GlobalPaymentsPage />
        </Layout>
      ),
    },
    {
      path: '/create-company',
      element: <CreateCompany />,
    },
    {
      path: '/create-company-error',
      element: <CreateCompanyError />,
    },
    {
      path: '/currency-pairs',
      element: (
        <Layout>
          <CurrencyPairs />
        </Layout>
      ),
    },
    {
      path: '/chains',
      element: (
        <Layout>
          <Chains />
        </Layout>
      ),
    },
    {
      path: '/chains/create-chain',
      element: (
        <Layout>
          <CreateChainPage />
        </Layout>
      ),
    },
    {
      path: '/chains/create-chain/:chainId',
      element: (
        <Layout>
          <CreateChainPage />
        </Layout>
      ),
    },
    {
      path: '/chains/:chainId',
      element: (
        <Layout>
          <MoreOneChain />
        </Layout>
      ),
    },
    {
      path: '/chains/:chainId/:operationId',
      element: (
        <Layout>
          <MoreOneOperation />
        </Layout>
      ),
    },
    {
      path: '/partners',
      element: (
        <Layout>
          <Partners />
        </Layout>
      ),
    },
    {
      path: '/points',
      element: (
        <Layout>
          <ExchangePoints />
        </Layout>
      ),
    },
    {
      path: '/points/create',
      element: (
        <Layout>
          <CreateExchangePoint />
        </Layout>
      ),
    },
    {
      path: '/points/:id',
      element: (
        <Layout>
          <ExchangePoint />
        </Layout>
      ),
    },
    {
      path: '/points/:id/publish',
      element: <PublishExchangePoint />,
    },
    {
      path: '/points/:id/edit',
      element: <EditExchangePoint />,
    },
    {
      path: '/points/:id/edit/rates',
      element: <EditExchangePointRates />,
    },
    {
      path: '/daily-reports/',
      element: (
        <Layout>
          <DailyReports />
        </Layout>
      ),
    },
    {
      path: '/daily-reports/:id',
      element: (
        <Layout>
          <DailyReport />
        </Layout>
      ),
    },
    {
      path: '/currencies/',
      element: (
        <Layout>
          <Currencies />
        </Layout>
      ),
    },
    {
      path: '/staff/',
      element: (
        <Layout>
          <Staff />
        </Layout>
      ),
    },
    {
      path: '/payment-methods/',
      element: (
        <Layout>
          <Payment />
        </Layout>
      ),
    },
    {
      path: '/staff/:id',
      element: (
        <Layout>
          <AboutStaff />
        </Layout>
      ),
    },
    {
      path: '/roles/',
      element: (
        <Layout>
          <Roles />
        </Layout>
      ),
    },
    {
      path: '/currency-pairs/course-history',
      element: (
        <Layout>
          <CourseHistory />
        </Layout>
      ),
    },
    // возможно пригодиться в будущем
    // {
    //   path: '/questions-answers',
    //   element: (
    //     <Layout>
    //       <QuestionsAnswers />
    //     </Layout>
    //   ),
    // },
    // {
    //   path: '/questions-answers/article',
    //   element: (
    //     <Layout>
    //       <QuestionsAnswersArticle />
    //     </Layout>
    //   ),
    // },
    {
      path: '/company',
      element: (
        <Layout>
          <Company />
        </Layout>
      ),
    },
    {
      path: '*',
      element: (
        <SessionMiddleware isAuth={!!cookie['token']}>
          <Navigate to={'/redirect-page'} />
        </SessionMiddleware>
      ),
    },
    {
      path: '/offers/confirm-request/:id',
      element: (
        <Layout noPadding>
          <OfferRequestConfirmPage />
        </Layout>
      ),
    },
    {
      path: '/payment-methods/:id',
      element: (
        <Layout>
          <PaymentMethodPage />
        </Layout>
      ),
    },
  ];
  // TODO: Add company route

  // if (!window.location.href.includes('edenex')) {
  // routesWithCompany.push({
  //   path: ,
  //   element: ,
  // });
  // }

  const accessDeniedRoute = [
    {
      path: '/redirect-page',
      element: <RedirectPage status={isBannedUser} />,
    },
    {
      path: '/access-denied',
      element: <AccessDeniedError />,
    },
    {
      path: '*',
      element: <Navigate to={'/access-denied'} />,
    },
  ];

  const routes = isBannedUser
    ? accessDeniedRoute
    : !!localStorage.getItem('companyId')
    ? routesWithCompany
    : routesWithoutCompany;

  useEffect(() => {
    const updateUsernameListener = fb.addListener(
      'updateUsername',
      ({ email, role }: { email: string; role?: string }) => {
        setUserEmail(email);
        role && setUserRole(role);
      }
    );
    return () => {
      updateUsernameListener.remove();
    };
  }, [userApi?.email]);

  return (
    <>
      <Refine
        i18nProvider={i18nProvider}
        dataProvider={dataProvider}
        LoginPage={Login}
        Header={Header}
        Sider={Sidebar}
        Title={Title}
        routerProvider={{ ...routerProvider, routes } as IRouterProvider}
        // @ts-ignore
        resources={resources}
        options={{
          syncWithLocation: true,
          warnWhenUnsavedChanges: false,
          disableTelemetry: true,
        }}
        catchAll={<ErrorComponent />}
      />
    </>
  );
}

export default App;
