import CreateOfferLayout from './CreateOfferLayout';
import {
  Button,
  Checkbox,
  Form,
  FormInstance,
  Input,
  Select,
  Space,
  Spin,
} from '@pankod/refine-antd';
import {
  CheckboxWrapper,
  CommentWrapper,
  CourseInputWrapper,
  CreateOfferFormInner,
  CreateOfferFormItem,
  FormItemsContainer,
  IconContainer,
  LabelText,
  NotFoundButtonStyled,
  NotFoundStyled,
  NotFoundTitleStyled,
  OptionItemContainer,
  PaymentMethodContainer,
  PaymentMethodTitle,
  ReverseCourseCaption,
  SegmentedBlue,
  SelectRow,
  SpinWrapperStyled,
  SwapOutlinedStyled,
} from './CreateOffer.styles';
import LabelWithPopover from './LabelWithPopover';
import { AdaptiveFullScreenLoader } from '../../../shared/components/full-screen-loader';
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { customInfinityScroll } from '../../../shared/helpers/script';
import { useCurrency } from '../model/hooks/useCurrency';
import CountrySelectFromItem from '../../../shared/components/ui/form-item/CountrySelectFromItem';
import { useCitiesAutocomplete } from '../../../shared/hooks/useCitiesAutocomplete';
import useDebounce from '../../../shared/hooks/useDebounce';
import {
  PaymentMethods,
  TExchangeRateSource,
  TOfferTypeSource,
} from '../model/types';
import { ExchangePointsContext } from '../../../shared/contexts/exchange-points-context';
import NoDataDisplay from '../../../shared/components/NoDataDisplay';
import { getCourse } from '../model/request/getCourse';
import { useCreateOfferState } from '../model/CreateOfferState';
import BigNumber from 'bignumber.js';
import SelfControlInput from './SelfControlInput';
import { DEFAULT_ACTIVE, DEFAULT_FIAT, DEFAULT_TYPE } from '../model/const';
import { useTranslation } from 'react-i18next';
import { getOfferCommission } from '../../../shared/api/getOfferCommission/getOfferCommission';
import { removeTrailingZeros } from '../../../shared/helpers/removeTrailingZeros';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import BlueTag from '../../../shared/components/ui/tags/BlueTag';
import { CurrencyIcon } from '../../../shared/ui-kit/CurrencyIcon';
import { getPaymentMethods } from '../model/request/getPaymentMethods';
import { useNavigate } from 'react-router';
import { CustomPopover } from './CustomPopover';

interface ICreateOfferForm {
  form: FormInstance;
}

const { Option } = Select;

const CreateOfferForm = ({ form }: ICreateOfferForm) => {
  const { t } = useTranslation(['shared']);
  const navigate = useNavigate();
  const { activePoint } = useContext(ExchangePointsContext);
  const exchangeRateSourceField: TExchangeRateSource = Form.useWatch(
    'exchange_rate_source',
    form
  );
  const cashCurrencyCodeField = Form.useWatch('cash_currency_code', form);

  const courseSoursOptions: { label: string; value: TExchangeRateSource }[] = [
    {
      label: t('shared:тексты.Не выбрано'),
      value: 'manual',
    },
    {
      label: 'Bitcoin Average',
      value: 'bitcoin_average',
    },
  ];

  const offerTypeOptions: { label: string; value: TOfferTypeSource }[] = [
    {
      label: t('shared:тексты.Наличные'),
      value: 'cash',
    },
    {
      label: t('shared:тексты.Безналичные'),
      value: 'emoney',
    },
  ];

  const currencyWithoutCash = useMemo(
    () => cashCurrencyCodeField?.replace('CASH', ''),
    [cashCurrencyCodeField]
  );

  const countryCodeField = Form.useWatch('country_code', form);
  const offerDirection = Form.useWatch('is_buying_crypto', form);
  const pointCommission = Form.useWatch('commission_percent', form);
  const cryptoCommissionAmount = Form.useWatch(
    'crypto_commission_amount',
    form
  );

  const initial_crypto_amount = useCreateOfferState(
    (state) => state.initial_crypto_amount
  );

  let { minutes, hours, days } = useCreateOfferState(
    (state) => state.freezingPeriod
  );

  const changeTimer = useCreateOfferState(
    (state) => state.changeFreezingPeriod
  );

  const type = useCreateOfferState((state) => state.offer_type);
  const usedCourse = useCreateOfferState((state) => state.usedCourse);
  const directCourse = useCreateOfferState((state) => state.directCourse);
  const isReverseCourse = useCreateOfferState(
    (state) => state.isReversedCourse
  );
  const changeCourse = useCreateOfferState((state) => state.changeCourse);
  const changeType = useCreateOfferState((state) => state.changeType);

  const [edenexCommission, setEdenexCommission] = useState<null | string>(null);

  if (type === 'emoney') {
    hours = 1;
    minutes = 0;
  }

  const clientCourse = useMemo(() => {
    if (!!directCourse && !!pointCommission) {
      return offerDirection === 1
        ? BigNumber(directCourse)
            .multipliedBy(1 - Number(pointCommission) / 100)
            .toFixed(8)
        : BigNumber(directCourse)
            .multipliedBy(1 + Number(pointCommission) / 100)
            .toFixed(8);
    } else return null;
  }, [pointCommission, directCourse, offerDirection]);

  const maxTransactionAmount = useMemo(() => {
    // подсчет максимального лимита для сделки (в CASH валюте)
    if (!initial_crypto_amount || !directCourse) return null;
    if (offerDirection === 0) {
      // по продает актив
      const temp = BigNumber(initial_crypto_amount).multipliedBy(
        BigNumber(100).div(100 - Number(edenexCommission ?? 0))
      );
      const temp2 = BigNumber(temp).plus(cryptoCommissionAmount);
      return BigNumber(temp2).multipliedBy(Number(clientCourse)).toFixed(8, 1);
    }
    if (offerDirection === 1) {
      // по покупает актив
      const temp = BigNumber(initial_crypto_amount).minus(
        BigNumber(initial_crypto_amount).multipliedBy(
          BigNumber(edenexCommission ?? 0).div(100)
        )
      );
      const temp2 = BigNumber(temp)
        .minus(cryptoCommissionAmount)
        .multipliedBy(Number(clientCourse))
        .toFixed(8, 1);
      return removeTrailingZeros(temp2);
    }
  }, [
    initial_crypto_amount,
    directCourse,
    offerDirection,
    pointCommission,
    cryptoCommissionAmount,
    edenexCommission,
  ]);

  const [currencySearch, setCurrencySearch] = useState('');
  const [paymentMethodsState, setPaymentMethodsState] = useState<
    PaymentMethods | []
  >([]);
  const [citySearch, setCitySearch] = useState('');

  const debounceCitySearch = useDebounce(citySearch, 1000);

  const {
    currencies,
    isLoading: currencyIsLoading,
    getNextCurrencies,
    page,
    maxPage,
  } = useCurrency(currencySearch);

  const { cities, isLoading: isLoadingCities } = useCitiesAutocomplete(
    debounceCitySearch,
    countryCodeField
  );

  const resetCurrencySearch = () => {
    setCurrencySearch('');
  };

  const resetPaymentMethodsSearch = async () => {
    const currency =
      form.getFieldValue('cash_currency_code').replace('CASH', '') ?? 'RUB';
    const paymentMethods = await getPaymentMethods(currency);
    setPaymentMethodsState(paymentMethods ?? []);
  };

  const handleReverseCourse = () => {
    useCreateOfferState.getState().changeReverseCourse();
  };

  const onCurrencyScroll = (event: any) => {
    if (page < maxPage && !currencyIsLoading && customInfinityScroll(event)) {
      getNextCurrencies();
    }
  };

  const setCourse = async () => {
    const fiat = form.getFieldValue('cash_currency_code');
    if (!fiat || form.getFieldValue('exchange_rate_source') === 'manual')
      return;
    const course = await getCourse({
      primary_currency_key: DEFAULT_ACTIVE.value,
      secondary_currency_key: fiat,
    });

    if (course) {
      changeCourse(course);
    }
  };

  const setPaymentMethods = async () => {
    const currency =
      form.getFieldValue('cash_currency_code').replace('CASH', '') ?? 'RUB';
    const paymentMethods = await getPaymentMethods(currency);
    if (paymentMethods && paymentMethods.length > 0) {
      setPaymentMethodsState(paymentMethods);
    } else {
      setPaymentMethodsState([]);
    }
  };

  let searchTimer: ReturnType<typeof setTimeout>;
  const handleSearchCurrency = (value: string) => {
    clearTimeout(searchTimer);
    if (value === '') {
      setCurrencySearch(value);
      return;
    }
    searchTimer = setTimeout(() => {
      setCurrencySearch(value);
    }, 1000);
  };

  const handleSelectFiat = async () => {
    await setCourse();
    setPaymentMethodsState([]);
    setSelectedItemsCount(0);
    form.setFieldValue('offer_requisites', []);
    await setPaymentMethods();
  };

  const handleSearchPaymentMethod = (value: string) => {
    const currency =
      form.getFieldValue('cash_currency_code').replace('CASH', '') ?? 'RUB';
    clearTimeout(searchTimer);
    if (value === '') {
      getPaymentMethods(currency, '').then(
        (res: PaymentMethods | undefined) => {
          setPaymentMethodsState(res ?? []);
        }
      );
      return;
    }
    searchTimer = setTimeout(() => {
      getPaymentMethods(currency, value).then(
        (res: PaymentMethods | undefined) => {
          setPaymentMethodsState(res ?? []);
        }
      );
    }, 1000);
  };

  const hanleSelectRateSourse = async (rateSourse: string) => {
    if ((rateSourse as TExchangeRateSource) === 'manual') {
      changeCourse(undefined);
    } else {
      await setCourse();
    }
  };

  const amountValidator = (_: any, amount: number | null | undefined) => {
    if (amount === null || amount === undefined) {
      return Promise.reject(t('shared:ошибки.Введите актив'));
    } else if (Number(amount) <= 0) {
      return Promise.reject(t('shared:ошибки.Введите значение больше 0'));
    } else {
      return Promise.resolve();
    }
  };

  const cashMinAmountValidator = (_: any, minAmount: string | undefined) => {
    const maxAmount: number | undefined = form.getFieldValue('cash_max_amount');
    if (!minAmount) {
      return Promise.reject(t('shared:ошибки.Введите мин.сумму сделки'));
    } else if (Number(minAmount) === 0) {
      return Promise.reject(t('shared:ошибки.Введите значение больше 0'));
    } else if (
      (!!minAmount && !maxAmount) ||
      Number(minAmount) > Number(maxAmount)
    ) {
      return Promise.reject(
        t('shared:ошибки.Сумма должна быть меньше макс. сумма сделки')
      );
    }
    return Promise.resolve();
  };

  const cashMaxAmountValidator = (_: any, maxAmount: string | undefined) => {
    const minAmount: number | undefined = form.getFieldValue('cash_min_amount');
    if (!maxAmount) {
      return Promise.reject(t('shared:ошибки.Введите макс.сумму сделки'));
    } else if (Number(maxAmount) === 0) {
      return Promise.reject(t('shared:ошибки.Введите значение больше 0'));
    }
    if (!!maxAmount && !!minAmount && Number(minAmount) > Number(maxAmount)) {
      return Promise.reject(
        t('shared:ошибки.Сумма должна быть больше мин. сумма сделки')
      );
    } else if (
      !!maxTransactionAmount &&
      Number(maxTransactionAmount) < Number(maxAmount)
    )
      return Promise.reject(
        `${t(
          'shared:ошибки.Сумма должна быть меньше или равна'
        )} ${maxTransactionAmount} ${currencyWithoutCash}`
      );
    return Promise.resolve();
  };

  const cityValidator = (_: any, city: string | undefined) => {
    if (type === 'emoney') {
      return Promise.resolve();
    }
    if (!city && countryCodeField) {
      return Promise.reject(
        t('shared:ошибки.Выберите город проведения обмена')
      );
    }
    return Promise.resolve();
  };

  const courseValidator = () => {
    if (usedCourse === undefined || usedCourse === '') {
      return Promise.reject(t('shared:ошибки.Введите курс обмена'));
    } else if (Number(usedCourse) === 0) {
      return Promise.reject(t('shared:ошибки.Введите значение больше 0'));
    }
    return Promise.resolve();
  };
  const handleChangeCitySearch = (value: string) => {
    setCitySearch(value);
  };

  const clearChoosenCity = () => {
    form.setFieldValue('city', undefined);
  };

  const handleChangeCourse = (e: ChangeEvent<HTMLInputElement>) => {
    changeCourse(e.target.value);
  };

  const handleChangeOfferType = (value: string) => {
    changeType(form.getFieldValue('type'));
    form.setFieldValue('type', value);
    clearChoosenCity();
  };

  const cityOptions = cities.reduce((acc, el) => {
    if (!!el.properties.state) {
      acc.push({
        value: el.properties.city ?? el.properties.county,
        label: `${el.properties.city ?? el.properties.county}, ${
          el.properties.state
        }`,
      });
    }
    return acc;
  }, [] as { value: string; label: string }[]);

  const onCityChange = (value: string) => {
    const city = cities.find(
      (c) => c.properties.city === value || c.properties.county === value
    );
    if (city) {
      const state = city.properties.state;
      if (state) {
        form.setFieldValue('state', state);
      }
      const longitude = city.geometry.coordinates[0];
      const latitude = city.geometry.coordinates[1];
      form.setFieldValue('longitude', longitude);
      form.setFieldValue('latitude', latitude);
    }
    // мб понадобится
    // const longitude = cities.find((c) => c.properties.city === value);
  };

  const tagRender = (props: CustomTagProps) => {
    const { closable, onClose, value } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const neededInfo: any = paymentMethodsState.find(
      (method: any) => method.id === value
    );
    return (
      <BlueTag
        onMouseDown={onPreventMouseDown}
        closable={true}
        onClose={onClose}
        style={{ marginRight: 4 }}
      >
        {neededInfo?.currency?.name}
      </BlueTag>
    );
  };

  const [disableCheck, setDisableCheck] = useState(false);
  const [selectedItemsCount, setSelectedItemsCount] = useState(0);

  const changePaymentMethodsHandler = (valuesArr: string[]) => {
    setSelectedItemsCount(valuesArr.length);
    resetPaymentMethodsSearch();
    if (valuesArr.length === 5) {
      setDisableCheck(true);
      return;
    } else if (valuesArr.length < 5) {
      setDisableCheck(false);
    }
  };

  const redirectToPaymentMethodsHandler = () => {
    navigate(`/payment-methods`);
  };

  useEffect(() => {
    if (activePoint) {
      form.setFieldsValue({
        country_code: activePoint.country,
        city: activePoint.city,
        state: activePoint.state,
        latitude: activePoint.latitude,
        longitude: activePoint.longitude,
      });
    }
  }, [activePoint?.id]);

  useEffect(() => {
    if (!edenexCommission && !!usedCourse) {
      getOfferCommission().then((r) => setEdenexCommission(r));
    }
  });

  useEffect(() => {
    if (type === 'emoney' && cashCurrencyCodeField === 'RUB') {
      setPaymentMethods().then();
    }
    if (type === 'cash') {
      setPaymentMethodsState([]);
      setSelectedItemsCount(0);
      form.setFieldValue('offer_requisites', []);
    }
  }, [cashCurrencyCodeField, type]);

  return (
    <CreateOfferLayout>
      <Form
        form={form}
        initialValues={{
          is_buying_crypto: 1,
          exchange_rate_source: 'manual',
          cash_currency_code: DEFAULT_FIAT.value,
          crypto_commission_amount: (0).toFixed(2),
          commission_percent: (0).toFixed(2),
          type: DEFAULT_TYPE.value,
        }}
        layout="vertical"
      >
        <CreateOfferFormItem name={'is_buying_crypto'}>
          <SegmentedBlue
            options={[
              {
                label: `${t('shared:тексты.Покупка')} ${DEFAULT_ACTIVE.label}`,
                value: 1,
              },
              {
                label: `${t('shared:тексты.Продажа')} ${DEFAULT_ACTIVE.label}`,
                value: 0,
              },
            ]}
          />
        </CreateOfferFormItem>

        <CreateOfferFormInner>
          <CreateOfferFormItem
            name={'initial_crypto_amount'}
            label={
              <LabelWithPopover
                labelText={t('shared:тексты.Актив')}
                requiredField
              />
            }
            rules={[
              {
                required: true,
                validator: amountValidator,
              },
            ]}
          >
            <SelfControlInput
              field={'initial_crypto_amount'}
              suffix={DEFAULT_ACTIVE.label}
            />
          </CreateOfferFormItem>
          <FormItemsContainer>
            <CreateOfferFormItem
              $small={true}
              name={'type'}
              label={
                <LabelWithPopover
                  labelText={t('shared:тексты.Тип валюты')}
                  requiredField
                />
              }
            >
              <Select
                defaultValue={'cash'}
                value={'cash'}
                options={offerTypeOptions}
                onSelect={handleChangeOfferType}
              />
            </CreateOfferFormItem>
            <CreateOfferFormItem
              $small={true}
              name={'cash_currency_code'}
              label={
                <LabelWithPopover
                  labelText={t('shared:тексты.Фиат')}
                  requiredField
                />
              }
              rules={[
                {
                  required: true,
                  message: t('shared:ошибки.Выберите фиат'),
                },
              ]}
              required
            >
              <Select
                onPopupScroll={onCurrencyScroll}
                onChange={resetCurrencySearch}
                onBlur={resetCurrencySearch}
                onSearch={handleSearchCurrency}
                onSelect={handleSelectFiat}
                filterOption={() => true}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    {currencyIsLoading && (
                      <SelectRow>
                        <AdaptiveFullScreenLoader />
                      </SelectRow>
                    )}
                  </>
                )}
                showSearch
              >
                {currencies.map((c) => (
                  <Option value={c.currency_key} key={c.currency_key}>
                    {c.currency_key}
                  </Option>
                ))}
              </Select>
            </CreateOfferFormItem>
          </FormItemsContainer>
          <CreateOfferFormItem
            dependencies={['type']}
            name={'offer_requisites'}
            label={
              <LabelWithPopover
                labelText={
                  t('shared:тексты.Способы оплаты') +
                  ` (${selectedItemsCount}/5)`
                }
                requiredField
              />
            }
            rules={[
              {
                required: type === 'emoney',
                message: t('shared:тексты.Выберите способ оплаты'),
              },
            ]}
          >
            <Select
              style={{ width: '100%' }}
              disabled={type === 'cash'}
              mode="multiple"
              onSearch={handleSearchPaymentMethod}
              onChange={changePaymentMethodsHandler}
              onBlur={resetPaymentMethodsSearch}
              filterOption={() => true}
              showSearch={true}
              allowClear={true}
              maxTagCount="responsive"
              tagRender={tagRender}
              placeholder={t('shared:тексты.Не выбрано')}
              notFoundContent={
                <NotFoundStyled>
                  <NotFoundTitleStyled>
                    {t('shared:тексты.Создайте подходящий способ оплаты')}
                  </NotFoundTitleStyled>
                  <div>{t('shared:тексты.В способах оплаты компании')}</div>
                  <NotFoundButtonStyled
                    onClick={redirectToPaymentMethodsHandler}
                  >
                    <div>{t('shared:тексты.Перейти в способы оплаты')}</div>
                  </NotFoundButtonStyled>
                </NotFoundStyled>
              }
            >
              {paymentMethodsState?.length &&
                paymentMethodsState?.map((method: any) => {
                  return (
                    <Option
                      key={method.id}
                      value={method.id}
                      customProp={method.currency.name}
                      disabled={disableCheck}
                    >
                      <OptionItemContainer>
                        <IconContainer>
                          <CurrencyIcon
                            width={24}
                            heigth={24}
                            code={method.currency.code}
                          />
                        </IconContainer>
                        <PaymentMethodContainer>
                          <PaymentMethodTitle>
                            {method.currency.name}
                          </PaymentMethodTitle>
                          <div>{method.requisites}</div>
                        </PaymentMethodContainer>
                      </OptionItemContainer>
                    </Option>
                  );
                })}
            </Select>
          </CreateOfferFormItem>

          <CreateOfferFormItem
            name={'exchange_rate_source'}
            label={t('shared:тексты.Источник курса')}
          >
            <Select
              defaultValue={'manual'}
              options={courseSoursOptions}
              onSelect={hanleSelectRateSourse}
            />
          </CreateOfferFormItem>
          <CustomPopover
            placement={'topLeft'}
            isHideTooltip={exchangeRateSourceField === 'manual'}
            tooltipText={t(
              'shared:тексты.Вы не можете изменить курс обмена, так как источник курса выбран'
            )}
          >
            <CreateOfferFormItem
              name={'course'}
              label={
                <LabelText required>{t('shared:тексты.Курс обмена')}</LabelText>
              }
              rules={[
                {
                  validator: courseValidator,
                },
              ]}
              required
            >
              <CourseInputWrapper>
                <Space size={[4, 4]}>
                  <ReverseCourseCaption>{`1 ${
                    isReverseCourse ? currencyWithoutCash : DEFAULT_ACTIVE.label
                  }`}</ReverseCourseCaption>
                  <Button
                    size={'small'}
                    type="text"
                    onClick={handleReverseCourse}
                    icon={<SwapOutlinedStyled style={{ fontSize: 16 }} />}
                  />
                </Space>

                <Input
                  value={usedCourse}
                  onChange={handleChangeCourse}
                  disabled={exchangeRateSourceField !== 'manual'}
                  suffix={
                    isReverseCourse ? DEFAULT_ACTIVE.label : currencyWithoutCash
                  }
                />
              </CourseInputWrapper>
            </CreateOfferFormItem>
          </CustomPopover>
          <CreateOfferFormItem
            label={
              <LabelWithPopover
                tooltipText={String(
                  t(
                    'shared:тексты.Доступное время для заморозки курса от 5 минут до 7 дней'
                  )
                )}
                isHideTooltip={type === 'emoney'}
                labelText={t('shared:тексты.Срок заморозки курса')}
                caption={String(t('shared:тексты.от 5 мин до 7 дней'))}
                requiredField
              />
            }
            required
          >
            <Space.Compact>
              <Input
                disabled={type === 'emoney'}
                suffix={t('shared:тексты.д')}
                value={days}
                onChange={(e) => changeTimer('days', e.target.value)}
              />
              <Input
                disabled={type === 'emoney'}
                suffix={t('shared:тексты.ч')}
                value={hours}
                onChange={(e) => changeTimer('hours', e.target.value)}
              />
              <Input
                disabled={type === 'emoney'}
                suffix={t('shared:тексты.м')}
                value={minutes}
                onChange={(e) => changeTimer('minutes', e.target.value)}
              />
            </Space.Compact>
          </CreateOfferFormItem>
          <CreateOfferFormItem
            name={'cash_min_amount'}
            label={
              <LabelWithPopover
                tooltipText={`Больше 0,00 ${currencyWithoutCash}`}
                labelText={t('shared:тексты.Минимальный лимит')}
                caption={'>0,00'}
                requiredField
              />
            }
            required
            dependencies={['cash_max_amount']}
            rules={[
              {
                required: true,
                validator: cashMinAmountValidator,
              },
            ]}
          >
            <SelfControlInput
              field={'cash_min_amount'}
              suffix={currencyWithoutCash}
            />
          </CreateOfferFormItem>
          <CreateOfferFormItem
            dependencies={[
              'cash_min_amount',
              'course',
              'initial_crypto_amount',
            ]}
            rules={[{ validator: cashMaxAmountValidator }]}
            name={'cash_max_amount'}
            label={
              <LabelWithPopover
                tooltipText={
                  !maxTransactionAmount
                    ? undefined
                    : `${t(
                        'shared:тексты.Не более'
                      )} ${maxTransactionAmount} ${currencyWithoutCash}`
                }
                labelText={t('shared:тексты.Максимальный лимит')}
                caption={
                  !maxTransactionAmount
                    ? undefined
                    : `≤ ${maxTransactionAmount} ${currencyWithoutCash}`
                }
                requiredField
              />
            }
            required
          >
            <SelfControlInput
              field={'cash_max_amount'}
              suffix={currencyWithoutCash}
            />
          </CreateOfferFormItem>
          <FormItemsContainer>
            <CreateOfferFormItem
              $small={true}
              name={'commission_percent'}
              label={
                <LabelWithPopover
                  labelText={t('shared:тексты.Комиссия пункта')}
                  requiredField
                />
              }
              rules={[
                {
                  required: true,
                  message: t('shared:ошибки.Введите комиссию пункта обмена'),
                },
              ]}
            >
              <SelfControlInput field={'commission_percent'} suffix={'%'} />
            </CreateOfferFormItem>
            <CreateOfferFormItem
              $small={true}
              name={'crypto_commission_amount'}
              label={
                <LabelWithPopover
                  labelText={t('shared:тексты.Фикс. стоимость сделки')}
                  infoIconContent={t(
                    'shared:тексты.Сумма, которую берет пункт обмена вне зависимости от объема сделки'
                  )}
                  requiredField
                />
              }
              rules={[
                {
                  required: true,
                  message: t('shared:ошибки.Введите фикс. стоимость сделки'),
                },
              ]}
            >
              <SelfControlInput
                field={'crypto_commission_amount'}
                suffix={DEFAULT_ACTIVE.label}
              />
            </CreateOfferFormItem>
          </FormItemsContainer>
          <CountrySelectFromItem
            formItemProps={{
              rules: [
                {
                  required: true,
                  message: String(
                    t('shared:ошибки.Выберите страну проведения обмена')
                  ),
                },
              ],
              required: true,
              label: t('shared:тексты.Страна обмена'),
              name: 'country_code',
            }}
            onSelect={clearChoosenCity}
            onClear={clearChoosenCity}
          />
          <CreateOfferFormItem
            dependencies={['type']}
            name={'city'}
            label={
              <LabelWithPopover
                labelText={t('shared:тексты.Город обмена')}
                tooltipText={
                  !countryCodeField
                    ? String(
                        t(
                          'shared:тексты.Сначала выберите страну проведния обмена'
                        )
                      )
                    : undefined
                }
                placement={'topLeft'}
                requiredField
                tooltipOnLabel={!countryCodeField}
              />
            }
            rules={[
              {
                required: type === 'cash',
                validator: cityValidator,
              },
            ]}
          >
            <Select
              filterOption={() => true}
              disabled={type === 'emoney' || !countryCodeField}
              notFoundContent={
                isLoadingCities ? (
                  <SpinWrapperStyled>
                    <Spin />
                  </SpinWrapperStyled>
                ) : (
                  <NoDataDisplay />
                )
              }
              onSearch={handleChangeCitySearch}
              onChange={onCityChange}
              showSearch
              // options={cities?.map(
              //   ({ properties: { city, state, county } }) => ({
              //     value: city ?? county,
              //     label: `${city ?? county}, ${state}`,
              //   })
              // )}
              options={cityOptions}
              placeholder={t('shared:тексты.Выберите город')}
            />
          </CreateOfferFormItem>
          <Form.Item name={'state'} hidden />
          <Form.Item name={'latitude'} hidden />
          <Form.Item name={'longitude'} hidden />
          <CheckboxWrapper>
            <Checkbox checked disabled>
              {t('shared:тексты.Сделка с гарантом')}
            </Checkbox>
          </CheckboxWrapper>
          <CommentWrapper>
            <CreateOfferFormItem
              name={'comment'}
              label={t('shared:тексты.Дополнительные условия сделки')}
            >
              <Input.TextArea
                placeholder={String(t('shared:тексты.Введите условия'))}
              />
            </CreateOfferFormItem>
          </CommentWrapper>
        </CreateOfferFormInner>
      </Form>
    </CreateOfferLayout>
  );
};

export default CreateOfferForm;
