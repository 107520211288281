import {
  ChatHeaderMainInfoStyled,
  ChatHeaderStyled,
  ChatHeaderSubInfoStyled,
  RefereeIconStyled,
  UserAvatarStyled,
} from './Chat.styles';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { RefereeOutlinedCircle } from '../../../../shared/components/icons';
import { Tooltip } from '@pankod/refine-antd';

type ChatHeaderProps = {
  clientNickName: string;
  clientRegisteredDate: string;
  isDealOnArgument: boolean;
};

export const ChatHeader = ({
  clientNickName,
  clientRegisteredDate,
  isDealOnArgument,
}: ChatHeaderProps) => {
  const { t } = useTranslation();

  return (
    <ChatHeaderStyled>
      <ChatHeaderMainInfoStyled>
        <UserAvatarStyled>
          <UserOutlined />
        </UserAvatarStyled>
        {isDealOnArgument && (
          <Tooltip
            trigger={'hover'}
            title={t('shared:чат.Рефери тултип')}
            placement={'bottomLeft'}
            align={{ offset: [-11, 1] }}
          >
            <RefereeIconStyled>
              <RefereeOutlinedCircle />
            </RefereeIconStyled>
          </Tooltip>
        )}
        <div>{clientNickName}</div>
      </ChatHeaderMainInfoStyled>
      <ChatHeaderSubInfoStyled>{`${t('shared:чат.Зарегистрирован')}: ${dayjs(
        clientRegisteredDate
      ).format('DD.MM.YY')}`}</ChatHeaderSubInfoStyled>
    </ChatHeaderStyled>
  );
};
